<template>
    <div class="flex flex-col items-center justify-center w-full img">
      <img
        class="w-full md:w-2/5 lg:w-1/5 h-auto"
        src="@/assets/images/eventful-loader.gif"
        alt="loading..."
        width="50"
        style="max-width: 100px"
      />
    </div>
  </template>
  
<script>
  export default {
    name: "eventful-loading-componenet"
  };
</script>
  