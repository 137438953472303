<template>
  <div>

    <div class="hidden lg:block">

      <vs-table
        class="mb-5"
        :sst="true"
        ref="SettlementsTable"
        @change-page="handleChangePage"
        v-model="selected"
        pagination
        :total="parseInt(tableConfig.total)"
        :max-items="parseInt(tableConfig.perPage)"
        :data="reviews"
        @selected="showData"
        stripe
      >
  
      <template  slot="thead">
          <template>
            <vs-th class="text-lg"> {{ $t('venueReviews.addedAt') }}</vs-th>
            <vs-th class="text-lg"> {{ $t('venueReviews.bookingRating') }}</vs-th>
            <vs-th class="text-lg"> {{ $t('venueReviews.bookingReview') }}</vs-th>
            <vs-th class="text-lg"> {{ $t('venueReviews.image') }}</vs-th>
            <vs-th class="text-lg"> {{ $t('venueReviews.status') }}</vs-th>
  
          </template>
      </template>
  
      <template slot-scope="{data}">
        <tbody>
          <template>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
  
                  <vs-td>
                    <p class="product-name font-medium truncate">{{moment(tr.created_at).locale($i18n.locale).fromNow()}}</p>
                  </vs-td>
  
                  <vs-td>
                    <div class="flex">
                        <span v-for="index in 5"> 
                          <svg v-if="index<=tr.rating" class="h-6 w-6 star-yellow" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                           <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                          </svg>
                          <svg v-else class="h-6 w-6 star-grey" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                           <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                          </svg>
                        </span>
                    </div>
                  </vs-td>
  
                 <vs-td>
  
                        <template v-if="tr.reviewable_type === 'App\\Booking'">
                            {{ reviewText(tr.review) }}
                         </template>
                      <template v-else-if="tr.reviewable_type === 'App\\BookingOption'">
                        {{ reviewText(tr.review) }}
                      </template>
                      <template v-else-if="tr.reviewable_type === 'App\\Venue'">
                        {{ reviewText(tr.review) }}
                      </template>
  
                 </vs-td>
  
  
                  <vs-td >
                      <div class="row">
                        <div v-for="(image, index) in tr.images" :key="`img-${index}`">
                          <div class="column">
                            <img :src="`${baseURL}/${image}`" alt="Image" width="100" height="80">
                          </div>
                        </div>
                      </div>
                  </vs-td>
  
                  <vs-td>
                    <vs-chip transparent color="primary" v-if="tr.status === 'visible_to_agent'">
                      <span class="truncate">
                        {{ $t('venueReviews.visibleToAgent') }}
                      </span>
                    </vs-chip>
                    <vs-chip transparent color="success" v-else-if="tr.status === 'visible_to_customers'">
                      <span class="truncate">
                        {{ $t('venueReviews.visibleToCustomers') }}
                      </span>
                    </vs-chip>
                  </vs-td>
  
  
              </vs-tr>
          </template>
        </tbody>
      </template>
      </vs-table>
    </div>
    <div class="block lg:hidden">
          <div class="grid grid-cols-1 gap-6">
            <div v-for="(review, index) in reviews" :key="index" class="p-4 border rounded-lg shadow-md ">
              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                    <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                  </div>
                  <div>
                    {{ $t('venueReviews.addedAt') }}:
                  </div>
                </div>
                <div>
                  {{ moment(review.created_at).locale($i18n.locale).fromNow() }}
                </div>
              </div>

              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                      <svg class="w-5 h-5 mb-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                    </svg>
                  </div>
                  <div>
                    {{ $t('venueReviews.bookingRating') }}:
                  </div>
                </div>
                <div>
                  <span v-for="i in 5" class="">
                    <svg
                      v-if="i <= review.rating"
                      class="h-6 w-6 star-yellow"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                    </svg>
                    <svg
                      v-else
                      class="h-6 w-6 star-grey"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                    </svg>
                  </span>                
                </div>
              </div>

              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                      <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                        <path d="M18 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h3.546l3.2 3.659a1 1 0 0 0 1.506 0L13.454 14H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-8 10H5a1 1 0 0 1 0-2h5a1 1 0 1 1 0 2Zm5-4H5a1 1 0 0 1 0-2h10a1 1 0 1 1 0 2Z"/>
                      </svg>
                  </div>
                  <div>
                    {{ $t('venueReviews.bookingReview') }}:
                  </div>
                </div>
                <div>
                  {{ reviewText(review.review) }}
                </div>
              </div>

              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                      <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.333 6.764a3 3 0 1 1 3.141-5.023M2.5 16H1v-2a4 4 0 0 1 4-4m7.379-8.121a3 3 0 1 1 2.976 5M15 10a4 4 0 0 1 4 4v2h-1.761M13 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 6h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z"/>
                      </svg>
                </div>
                <div>
                  {{ $t('venueReviews.status') }}:
                </div>
                </div>
                <div>
                  <vs-chip transparent color="primary" v-if="review.status === 'visible_to_agent'">
                  <span class="truncate ">{{ $t('venueReviews.visibleToAgent') }}</span>
                </vs-chip>
                <vs-chip transparent color="success" v-else-if="review.status === 'visible_to_customers'">
                  <span class="truncate">
                    {{ $t('venueReviews.visibleToCustomers') }}:
                  </span>
                </vs-chip>
               </div>
            </div>

              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                    <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                  <path fill="currentColor" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z"/>
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z"/>
                </svg>
                </div>
                <div>
                  {{ $t('venueReviews.image') }}:
                </div>
                </div>
                <div>
                  <div class="flex space-x-4">
                  <div v-for="(image, imgIndex) in review.images" :key="imgIndex" class="w-24 h-16 ">
                  <img :src="`${baseURL}/${image}`" alt="Image" class="w-full h-full object-cover rounded-sm"/>
                </div>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center mt-6"> 
                <vs-button @click="showData(review)" color="#666" type="border" icon="info" class="w-full hover:to-grey">
                  {{ $t('venueReviews.reviewDetails') }}
                </vs-button>
              </div>
          </div>
        </div>
        <vs-pagination class="mt-5" :total="Math.ceil(tableConfig.total / tableConfig.perPage)" :max="5" v-model="tableConfig.currentPage" @input="handleChangePage"></vs-pagination>
    </div>

  <review-details-popup :reviewDetailsActive.sync="reviewDetailsActive" :currentReview.sync="currentReview" />


 </div>
</template>

<script>
import moment from 'moment';
import ReviewDetailsPopup from '@/views/components/qosoor-components/Reviews/ReviewDetailsPopup.vue';

export default {
  components: {
    ReviewDetailsPopup

  },
  data () {
    return {
      moment:moment,
      selected: [],
      tableConfig: {
          total: 0,
          perPage: 10,
          currentPage: 1,
      },
      currentReview: null,
      reviewDetailsActive: false,
    }
  },
  computed: {
    reviews () {
      return this.$store.state.reviews.reviews;
    },
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    baseURL() {
      return process.env.VUE_APP_IMAGES_BASE_URL;

    },
  },

  created () {
    this.getReviews()
    window.addEventListener('changeLanguage', function(e){
      this.$store.dispatch('loader/loaderOn')
      Promise.all([ 
      ]).then(()=>{this.$store.dispatch('loader/loaderOff')}, this);
    }.bind(this));

  },

  methods: {
    async getReviews(page = 1) {
      let query = {
      
        page: page,
        perPage: this.tableConfig.perPage
      }

      this.$store.dispatch('loader/loaderOn')

      const response = await this.$store.dispatch('reviews/getReviews', {venueId : this.currentVenueId , params: query})

      this.$store.dispatch('loader/loaderOff')

      this.tableConfig.total = response.data.meta.total  
      
      
    },
    handleChangePage(page) {
      this.tableConfig.currentPage = page;
      this.getReviews(page);
    },
    showData (data) {
      this.reviewDetailsActive = true;
      this.currentReview = data;
    },
    reviewText(review) {
      if (review && review.length > 50) {
        return review.substring(0, 50) + '...';
      }
      return review;
    }
  },
  watch: {
    
  },

}
</script>

<style scoped>

.row {
  display: flex;
  
}

.column {
  flex: 33.33%;
  padding: 5px;
}
.vs-tooltip {
  z-index: 99999 !important;
}

.star-yellow{

color: rgb(240, 182, 36);}

.star-grey{
color: rgb(93, 92, 89);}

</style>
