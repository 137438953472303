<template>
    <div>
      <vs-popup class="holamundo " :title="$t('venueReviews.bookingDetails')" :active.sync="isActive" @close="closePopup">
        <div v-if="currentReview">
          <div class="grid grid-cols-5 gap-4">
            <div class="col-span-1"> 
              {{ $t('venueReviews.addedAt') }}
            </div>
            <div class="col-span-4"> 
              {{ formatReviewDate(currentReview.created_at) }}
            </div>
            <div class="col-span-1"> 
              {{ $t('venueReviews.bookingRating') }}:
            </div>
            <div class="col-span-4"> 
              <div class="flex">
                <span v-for="index in 5"> 
                  <svg v-if="index<=currentReview.rating" class="h-6 w-6 star-yellow" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                  </svg>
                  <svg v-else class="h-6 w-6 star-grey" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                  </svg>
                </span>
              </div>           
            </div>
            <div class="col-span-1"> 
              {{ $t('venueReviews.bookingReview') }}:
            </div>
            <div class="col-span-4"> 
              {{ (currentReview.review) }}
            </div>
            <div class="col-span-1"> 
              {{ $t('venueReviews.status') }}:
            </div>
            <div class="col-span-4"> 
              <vs-chip transparent color="primary" v-if="currentReview.status === 'visible_to_agent'">
                {{ $t('venueReviews.visibleToAgent') }}
              </vs-chip>
              <vs-chip transparent color="success" v-else-if="currentReview.status === 'visible_to_customers'">
                {{ $t('venueReviews.visibleToCustomers') }}
              </vs-chip>
            </div>
            <div class="col-span-1"> 
              {{ $t('venueReviews.image') }}:
            </div>
           <div class="col-span-4 gap-4 grid grid-cols-1 lg:grid-cols-2  "> 
             <img v-for="(image, index) in currentReview.images" :key="`img-${index}`" :src="`${baseURL}/${image}`" alt="Image" class="w-64 self-center rounded-lg" >
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
  
  <script>
  import moment from 'moment';
  import Loading from '@/views/components/qosoor-components/Loading/Loading.vue'


  export default {
    name: 'review-details-popup',
    components: {
      Loading
    },
    props: {
        reviewDetailsActive: {
          type: Boolean,
          default: false
        },
        currentReview: {
          type: Object,
          defult: null
        },
    },
    data () {
      return{}
    },
    computed: {
      isActive: {
        get() {
          return this.reviewDetailsActive
        },
        set(newState) {
          return newState
        }
      },

      reviews () {
        return this.$store.state.reviews.reviews;
      },


      baseURL() {
        return process.env.VUE_APP_IMAGES_BASE_URL;
      },

    },
    created () {
    },
    methods: {
      closePopup() {
        this.$emit('update:reviewDetailsActive', false);
      },
      formatReviewDate(date) {
        moment.locale(this.$i18n.locale);
      return moment(date).fromNow();
    },
    }
  
  }
  </script>
  
  <style>
@media (max-width: 468px) {
  .popup-content {
    width: 50%; 
  }
}

.header {
  font-weight: bold;
  display: inline-block;
  width: 190px;
  justify-content: start;
}

  .row {
  display: flex;
}

.column {
  flex: 33.33%;
  padding: 5px;
}
.vs-tooltip {
  z-index: 99999 !important;
}
.star-yellow{

color: rgb(240, 182, 36);}

.star-grey{
color: rgb(93, 92, 89);}


  
  </style> 
